import {httpGet,httpPut, httpPost} from './http'
import global from '../common/global';

//验证用户是否存在
const checkOnlyUser = (params)=>httpGet(`${global.gateway}/sys/user/checkOnlyUser`,params);

//改变密码
const updatePassword = (params)=>httpPut(`${global.gateway}/sys/user/updatePassword`,params);

// 机构注册
const bidRegister = params => httpPost(`${global.gateway}/sys/user/bidRegister`, params);

// 修改用户名（需输入用户密码）
const changeUsername= params => httpPut(`${global.gateway}/sys/user/changeUsername`, params);

// 用户登录出
const loginout = params => httpPost(`${global.gateway}/sys/logout`, params);

export {
  bidRegister,
  checkOnlyUser,
  updatePassword,
  changeUsername,
  loginout
}