<!--
 * @Description: 登录注册页
 * @Author: Niklaus
 * @Return:
 * @Date: 2022-08-08 16:50:49
 *-->
<template>
  <el-container>
    <div class="container-content">
      <div class="container--left" :class="{'show' :showRegister}">
        <el-form :model="login" :rules="rules" ref="login" label-width="70px" class="login-form login demo-dynamic">
          <h2>竞价供应商端</h2>
          <el-form-item label="用户名" prop="username">
            <el-input v-model="login.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="login.password" type="password" placeholder="请输入用密码"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <div>
              <el-input v-model="login.captcha" placeholder="请输入验证码" style="width:150px"></el-input>
              <img v-if="requestCodeSuccess" class="validCode"  :src="randCodeImage" @click="getValidCode"/>
              <img v-else src="./../assets/img/checkcode.png" class="validCode" @click="getValidCode"/>
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button type="primary" style="margin-top: 80px" size="large" @click="onLogin('login')">登录</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="container--right" :class="{'show' :showRegister}">
        <el-form :model="register" :rules="registerRules" ref="register" label-width="135px" class="login-form">
          <h2>竞价供应商注册</h2>
          <el-form-item label="用户名" prop="username">
            <el-input v-model="register.username" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="register.password" type="password" placeholder="由8位数字、字母和特殊符号组成"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password2">
            <el-input v-model="register.password2" type="password" placeholder="请输入用密码"></el-input>
          </el-form-item>
          <el-form-item label="供应商名称" prop="storeName">
            <el-input v-model="register.storeName" placeholder="请输入营业执照中的公司全称"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码"  prop="uscc">
            <el-input v-model="register.uscc" placeholder="请输入统一社会信用代码18位"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="linkMan">
            <el-input v-model="register.linkMan" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系地址" prop="linkAddr">
            <el-input v-model="register.linkAddr" placeholder="请输入联系地址"></el-input>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button type="primary" style="margin-top: 40px" size="large" @click="onRegister()">注册</el-button>
          </el-form-item>
        </el-form>
      </div>


      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay_panel overlay--left">
            <button class="btn" @click="showRegister = false">已有账号,直接登录</button>
          </div>

          <div class="overlay_panel overlay--right">
            <button class="btn" id="signup" @click="showRegister = true">没有账号,点击注册</button>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import{ httpGet, httpPost } from '@/utils/http'
import {bidRegister} from '@/utils/api'
import { checkUsername, checkPassword, checkConfirmPassword } from '@/utils/validator'
import global from '@/common/global'
export default {
  name: 'Login',
  data() {
    return {
      login: {
        username: '',
        password: '',
        captcha: '',
        userType: '000',
      },
      rules: {
        username: [{required:true, message: '请输入用户名', trigger:'blur'}],
        password: [{required:true, message: '请输入密码', trigger:'blur'}],
        captcha: [{required:true, message: '请输入验证码', trigger:'blur'}],
      },

      register: {
        username: '',
        password: '',
        storeName:"",
        uscc:"",
        linkMan:"",
        linkAddr:"",
        storeType:"02",//店铺类型 01采购单位02供应商 03代理机构
      },
      registerRules: {
        username: [
          { required: true,message: '请输入用户名', trigger: 'blur' },
          { validator: checkUsername, trigger: 'blur' }
        ],
        password: [
          { required: true,message: '请输入密码', trigger: 'blur'},
          { validator: checkPassword, trigger: 'blur'  }
        ],
        password2: [
          { required: true, message: '请输入密码' },
          { validator: this.handlePasswordCheck, trigger: 'blur'  }
        ],
        storeName: [{required:true, message: '请输入供应商机构名称', trigger:'blur'}],
        uscc: [
          {required:true, message: '请输入18位统一社会信用代码', trigger: 'blur' },
          { validator: this.handleUscc, trigger: 'blur' }
        ],
        linkMan: [{required:true, message: '请输入联系人', trigger:'blur'}],
        linkAddr: [{required:true, message: '请输入联系地址', trigger:'blur'}],
      },

      showRegister: false,
      requestCodeSuccess: false,
      randCodeImage: '',
      currdatetime: ''
    }
  },

  created() {
    this.getValidCode()
  },

  methods: {

    /**
     * @Description: 获取验证码
     * @Author: Niklaus
     * @Date: 2022-08-09 17:03:28
     */
    getValidCode() {
      this.currdatetime = new Date().getTime()
      httpGet(global.gateway + `/sys/randomImage/${this.currdatetime}`).then(({data}) => {
        if(data.success) {
          this.randCodeImage = data.result
          this.requestCodeSuccess = true
        }else
          this.requestCodeSuccess = false
      }).catch(()=>{
        this.requestCodeSuccess=false
      })
    },

    /**
     * @Description: 校验登录信息
     * @Author: Niklaus
     * @Date: 2022-08-09 09:32:16
     */
    onLogin() {
      this.$refs.login.validate(valid => {
        if(valid){
          let loginParams = {
            ...this.login,
            checkKey: this.currdatetime,
          }

          httpPost(global.gateway + `/sys/bidLogin?platform=1`, loginParams).then(({data}) => {
            if(data.success) {
              if(!data.result.ewStores[0].roleCode || data.result.ewStores[0].roleCode == '00') {
                this.$message({
                  title: '登录失败',
                  message: '用户无权限，请联系机构管理员',
                  type: 'error'
                })
              } else {
                this.$ls.set('token', data.result.token, 24 * 60 * 60 * 1000)
                this.$ls.set('userInfo', data.result.userInfo, 24 * 60 * 60 * 1000)
                this.$ls.set('storeInfo', data.result.ewStores[0], 24 * 60 * 60 * 1000)
                this.$router.replace({name:"home"})
              }
            }else {
              this.$message.error(data.message)
            }
          })
        }else{
          return false
        }

      })
    },

    /**
     * @Description: 机构注册
     * @Author: Niklaus
     * @Date: 2022-08-18 10:39:47
     */
    onRegister(){
      this.$refs.register.validate( valid => {
        if(valid) {
          bidRegister(this.register).then(res => {
            if(res.data.success) {
              this.$message.success('注册成功，请登录')
              this.getValidCode()
              this.showRegister = false
              this.resetRegister()
            }
          })
        }
      })
    },

    /**
     * @Description: 确认密码
     * @Author: Niklaus
     * @Date: 2022-08-17 15:45:24
     */
    handlePasswordCheck(rule, value, callback) {
      let password = this.register['password']
      checkConfirmPassword({...rule, password}, value, callback)
    },

    /**
     * @Description: 校验 统一社会信用代码
     * @Author: Niklaus
     * @Date: 2022-08-17 16:10:34
     */
    handleUscc(rule,value, callback) {
      let reg = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/
      if(!reg.test(value))
        callback(new Error('请输入正确的统一社会信用代码!'))
      else
        callback()
    },

    /**
     * @Description: 重置注册数据
     * @Author: Niklaus
     * @Date: 2022-08-18 10:40:00
     */
    resetRegister() {
      Object.keys(this.register).forEach(key => this.register[`${key}`] = '')
    }
  }


}
</script>

<style scoped>

.el-container {
  width: 100vw;
  height: 100vh;
  background: url(~@/assets/img/background.svg) center 100% / 100% no-repeat;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.container-content {
   width: 840px;
   height: 700px;
   display: flex;
   display: -webkit-flex;
   border-radius: 10px;
   overflow: hidden;
   position: relative;
   left: 130px;
}
.form-content {
  display: flex;
  display: -webkit-flex;
  position: relative;
  transition: transform .6s ease-in-out
}

.form-content.show {
  transform: translateX(-50%);
}
.overday {
  flex-shrink: 0;
  width: 420px;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

}
.overday .tips {
  cursor: pointer;
  color: white;
  font-weight: bold;
  padding: 15px 30px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #607ce3 0%, #409EFF 100%);
}

.login-form {
  padding: 30px 20px;
  width: 420px;
  height: 700px;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(5px);
}

.login-form h2 {
  text-align: center;
  padding: 15px 0;
  font-size: 26px;
  font-weight: bold;
}
.container--right h2 {
  margin-bottom: 30px;
}
.login.login-form h2 {
  margin-bottom: 100px;
}
.login-form button {
  margin-top: 25px;
  width: 100%;
}
.validCode {
  position: absolute;
  margin-left:20px;
  width:115px;
  height:40px;
  background-color:#ddd
}

.container--left, .container--right {
  display: flex;
  display: -webkit-flex;
  position: relative;
  z-index: 2;
  transition: all .6s ease-in-out;
}
.container--right {
  /* transform: translateX(100%); */
  opacity: 0;
  right: -50%;
}
.container--left.show,
.container--right.show {
  transform: translateX(-100%);
}
.container--right.show {
  animation:  show 0.6s;
  opacity: 1;
  z-index: 2;
}

@keyframes show {
  0%{
    opacity: 0;
    z-index: 1;
  }
  100% {
    opacity: 1;
    z-index: 2;
  }
}


.overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  z-index: 1;
}
.overlay-container .overlay {
  height: 100%;
  position: relative;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  width: 200%;
}
.overlay_panel {
  display: flex;
  display: -webkit-flex;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
}
.overlay--right {
  left: 50%;
}
.overlay_panel .btn {
  outline: none;
  width: 50%;
  background-color: #0367a6;
  border: 1px solid #0367a6;
  color: #e9e9e9;
  border-radius: 8px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.9rem;
  text-transform: uppercase;
  transition: tansform 80ms ease-in;
}
</style>