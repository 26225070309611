import { checkOnlyUser } from './api'
/** 校验用户名的唯一性 */
const checkUsername = (rule, value, callback) => {
  var reg=/^1[3456789]\d{9}$/
  if(!value){
    callback(new Error("请输入用户名"))
  }else if(!reg.test(value)){
    callback(new Error("请输入正确手机号"))
  }else{
    var params = {username: value};
    checkOnlyUser(params).then((res) => {
      if (res.data.success) {
        callback()
      } else {
        callback("用户名已存在!")
      }
    })
  }
}

/** 校验密码 */
const checkPassword = (rule, value, callback) => {
  let reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/;
  if (!reg.test(value))
    callback(new Error('密码由8位数字、字母和特殊符号组成!'))
  else
    callback()
}

/**
 * @Description: 确认密码
 * @Author: Niklaus
 * @Date: 2022-08-17 15:45:24
 */
 const checkConfirmPassword = (rule, value, callback) => {
  let password = rule['password']
  if (value === undefined) {
    callback(new Error('请输入密码'))
  }
  if (value && password && value.trim() !== password.trim()) {
    callback(new Error('两次密码不一致'))
  }
  callback()
}

export {
  checkUsername,
  checkPassword,
  checkConfirmPassword
}